import { notEmpty } from "@product/scmp-sdk";
import first from "lodash/first";
import { graphql } from "react-relay";
import { readInlineData } from "relay-runtime";

import { article as articleData, section as sectionData } from "scmp-app/data";
import type { helpersGetArticleTitleArticle$key } from "scmp-app/queries/__generated__/helpersGetArticleTitleArticle.graphql";
import type { helpersGetPodcastTitlePodcast$key } from "scmp-app/queries/__generated__/helpersGetPodcastTitlePodcast.graphql";
import type { helpersGetVideoTitleVideo$key } from "scmp-app/queries/__generated__/helpersGetVideoTitleVideo.graphql";

export const getVideoTitle = (reference?: helpersGetVideoTitleVideo$key) => {
  const video = readInlineData(
    graphql`
      fragment helpersGetVideoTitleVideo on Video @inline {
        sections {
          value {
            entityId
          }
        }
      }
    `,
    reference ?? null,
  );

  if (!notEmpty(video)) return;

  const sections = first(video?.sections);
  const sectionIds = (sections?.value ?? []).map(section => section?.entityId);
  const hasSCMPOriginals = sectionIds.includes(sectionData?.scmpOriginals?.entityId);

  return hasSCMPOriginals ? "SCMP Originals" : "Videos";
};

export const getPodcastTitle = (reference?: helpersGetPodcastTitlePodcast$key) => {
  const podcast = readInlineData(
    graphql`
      fragment helpersGetPodcastTitlePodcast on Podcast @inline {
        __typename
      }
    `,
    reference ?? null,
  );

  return podcast?.__typename === "Podcast" ? "PODCASTS" : undefined;
};

export const getArticleTitle = (reference?: helpersGetArticleTitleArticle$key) => {
  const article = readInlineData(
    graphql`
      fragment helpersGetArticleTitleArticle on Article @inline {
        types {
          value {
            entityId
          }
        }
        sections {
          value {
            entityId
          }
        }
      }
    `,
    reference ?? null,
  );

  if (!notEmpty(article)) return;

  const articleType = first(first(article?.types)?.value)?.entityId ?? "";

  if (articleType) {
    switch (articleType) {
      case articleData.types.blogs.entityId:
      case articleData.types.column.entityId:
      case articleData.types.debate.entityId:
      case articleData.types.opinion.entityId:
      case articleData.types.review.entityId:
        return "COMMENT";
      case articleData.types.infographic.entityId:
        return "Infographics";
      case articleData.types.live.entityId:
        return "Live";
      case articleData.types.series.entityId:
        return "SCMP Series";
    }
  }

  const sections = first(article?.sections);
  const sectionIds = (sections?.value ?? []).map(section => section?.entityId);
  const hasInfographic = sectionIds.includes(sectionData?.infographics?.entityId);

  return hasInfographic ? "INFOGRAPHICS" : undefined;
};
