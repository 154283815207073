import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { fontMerriweather, fontRoboto } from "@product/scmp-sdk";

import { Figure, StyledFigcaption } from "scmp-app/components/content/content-cover-image/styles";
import {
  H2Container as ContentSubHeadlineH2Container,
  H3Container as ContentSubHeadlineH3Container,
} from "scmp-app/components/content/content-sub-headline/styles";
import { EntityLink } from "scmp-app/components/entity-link";

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  inline-size: 100%;

  color: #000000;
  ${props => props.theme.breakpoints.up("tablet")} {
    flex-direction: column-reverse;
  }
  ${props => props.theme.breakpoints.up("homeDesktop")} {
    flex-direction: row;
  }
`;

export const Content = styled.div`
  ${props => props.theme.breakpoints.up("tablet")} {
    margin-block-end: 20px;
  }
  ${props => props.theme.breakpoints.up("homeDesktop")} {
    margin-block-end: 0;
  }
`;

export const StyledEntityLink = styled(EntityLink)``;

export const HeadlineContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Headline = styled.div`
  margin-block-end: 0;

  color: #000000;
  font-weight: 700;
  font-size: 18px;
  font-family: ${fontMerriweather};
  line-height: 25.2px;

  ${props => props.theme.breakpoints.up("tablet")} {
    font-size: 22px;
    line-height: 30.8px;
  }
  ${props => props.theme.breakpoints.up("homeDesktop")} {
    margin-block-end: 8px;
  }
`;

export const LiveTagContainer = styled.div`
  margin-block-end: 8px;

  :empty {
    margin-block-end: 0;
  }
`;

export const SubHeadline = styled.div`
  display: none;
  ${props => props.theme.breakpoints.up("homeDesktop")} {
    display: block;

    color: #6f6f6f;
    font-size: 14px;
    font-family: ${fontRoboto};
    line-height: 18.2px;
  }

  ${ContentSubHeadlineH2Container}, ${ContentSubHeadlineH3Container} {
    li + li {
      margin-block-start: 12px;
    }
  }
`;

export const ActionBar = styled.div`
  margin-block-start: 12px;
  time {
    color: #999999;
    font-size: 12px;
    line-height: 14px;
  }
`;

export const CoverImage = styled.div`
  block-size: auto;
  margin-block-end: 12px;
  margin-inline: -20px;
  ${Figure} {
    aspect-ratio: 3/2;
  }

  ${StyledFigcaption} {
    display: none;

    ${props => props.theme.breakpoints.up("tablet")} {
      display: block;

      line-height: 14px;
    }
  }
  ${props => props.theme.breakpoints.up("tablet")} {
    margin-block-end: 0;
    margin-inline: 0;
  }
  ${props => props.theme.breakpoints.up("homeDesktop")} {
    block-size: 224px;
    margin-inline-end: 24px;
  }
`;

const SubContentStyle = css`
  margin-block-start: 20px;
  padding-block-start: 20px;
  border-block-start: 1px solid rgba(0, 0, 0, 0.1);
`;

export const SubContentInMobileAndDesktop = styled.div`
  ${props => props.theme.breakpoints.up("tablet")} {
    display: none;
  }
  ${props => props.theme.breakpoints.up("homeDesktop")} {
    display: block;
    ${SubContentStyle};
  }
`;

export const SubContentInTablet = styled.div`
  ${SubContentStyle};
  display: none;
  ${props => props.theme.breakpoints.up("tablet")} {
    display: block;
  }
  ${props => props.theme.breakpoints.up("homeDesktop")} {
    display: none;
  }
`;
